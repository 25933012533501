*, :before, :after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --serif: "Source Serif Pro";
  --sans: "Source Sans Pro";
  --dark-color: #393e41;
  --medium-color: lightgray;
  --light-color: #fff;
  --pop-color: #173dde;
  --shadow: 0rem 0rem .25rem #0000001a, 0rem 0rem 1rem #00000026;
  --h1-size: 3.5rem;
  --h2-size: 2.75rem;
  --h3-size: 1.25rem;
  --body-size: 1rem;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: var(--sans, sans-serif);
  color: var(--dark-color);
  font-size: var(--body-size);
}

h1, h2, h3, h4, h5, h6, blockquote {
  font-family: var(--serif, serif);
  text-align: center;
  margin: 2rem auto;
}

blockquote {
  font-weight: normal;
}

h2 {
  font-size: var(--h2-size);
}

h3 {
  font-size: var(--h3-size);
}

small {
  font-size: .75rem;
}

address {
  font-style: normal;
}

.heading-emphasis:before, .heading-emphasis:after {
  content: "";
  background-color: var(--medium-color);
  height: 2px;
  opacity: .75;
  width: 50%;
  max-width: 250px;
  margin: 1rem auto;
  display: block;
}

img {
  display: block;
}

a {
  color: var(--dark-color);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

ul {
  list-style-type: none;
}

.button {
  background-color: var(--pop-color);
  color: var(--light-color);
  width: max-content;
  height: max-content;
  border: 2px solid var(--pop-color);
  cursor: pointer;
  border-radius: 5px;
  padding: .5rem 1.5rem;
  font-size: 1.25rem;
  transition: all .1s;
  display: block;
}

.button:hover, .button:focus {
  background-color: var(--light-color);
  color: var(--pop-color);
  text-decoration: none;
}

.button:focus {
  outline: 1px solid var(--pop-color);
  outline-offset: 3px;
}

.move-in {
  transform: translateX(100%);
}

header {
  z-index: 10;
  background-color: var(--light-color);
  width: 100%;
  height: 100%;
  text-transform: uppercase;
  transition: transform .15s;
  position: fixed;
  right: 100%;
}

nav {
  width: 100%;
  height: 100%;
  grid-template-rows: repeat(6, 1fr);
  display: grid;
}

nav a {
  color: var(--dark-color);
  text-align: center;
  place-self: center;
  padding: .5rem;
  font-size: 2.5rem;
  transition: all .2s;
  display: block;
}

nav a:hover {
  color: var(--pop-color);
  text-decoration: none;
}

.hamburger {
  z-index: 15;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  background-color: var(--light-color);
  border-radius: 5px;
  position: fixed;
  top: .5rem;
  left: .5rem;
}

.hamburger__line {
  width: 2.5rem;
  height: .3rem;
  background-color: var(--dark-color);
  cursor: pointer;
  border-radius: .25rem;
  margin: .52rem auto;
  transition: all .15s;
  display: block;
  position: relative;
}

.hamburger-transformation {
  background-color: var(--pop-color);
}

.hamburger-transformation--top {
  transform: translate(0, 12.5px)rotate(-45deg);
}

.hamburger-transformation--middle {
  transform: translate(1px)rotate(45deg);
}

.hamburger-transformation--bottom {
  transform: translate(0, -14px)rotate(45deg);
}

.home__container {
  min-height: 400px;
  grid-template-rows: auto;
  display: grid;
}

.home__container img {
  width: calc(100% - 2rem);
  max-width: 800px;
  margin: 4rem auto 1rem;
}

.floating-header {
  width: 80%;
}

.home__container blockquote {
  width: 80%;
  font-size: 2rem;
}

.home__container a {
  margin: 2rem auto 1rem;
}

.floating-header {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.services__flex-container {
  flex-wrap: wrap;
  justify-content: space-evenly;
  display: flex;
}

.services__card {
  background-color: var(--light-color);
  width: 350px;
  height: 600px;
  box-shadow: var(--shadow);
  border-radius: 5px;
  margin: 2rem;
  position: relative;
}

.services__card img {
  height: 150px;
  margin: 5rem auto 3rem;
}

.services__card h3 {
  margin: 3rem auto 2rem;
}

.services__card ul {
  width: 100%;
  text-align: center;
  margin: 2rem auto;
}

.services__icon--camera {
  width: 128px;
}

.services__card li {
  margin: 1rem;
}

.photos__container {
  flex-wrap: wrap;
  place-content: space-around space-evenly;
  display: flex;
}

.photos img {
  max-width: 400px;
  width: 80%;
  height: 100%;
  box-shadow: var(--shadow);
  border-radius: 5px;
  flex-basis: auto;
  margin: 1rem;
}

.photos a {
  margin: 4rem auto;
}

.about {
  width: 100%;
  margin: 5rem auto;
  overflow: hidden;
}

.about img {
  max-width: 350px;
  width: 80%;
  height: auto;
  box-shadow: var(--shadow);
  border-radius: 5px;
  margin: 1rem auto;
}

.about p {
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
  padding: 0 2rem;
  line-height: 1.5rem;
}

.estimate p {
  width: 100%;
  text-align: center;
  margin: 2rem auto;
}

form {
  box-shadow: var(--shadow);
  width: 90%;
  max-width: 600px;
  border-radius: 5px;
  margin: 0 auto;
  display: block;
  overflow: hidden;
}

label:nth-child(1) {
  margin-top: 5rem;
}

label, input, textarea {
  width: 80%;
  min-width: 250px;
  border: none;
  outline: none;
  display: block;
}

label {
  margin: 2rem auto .5rem;
}

input, textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  border: 1px solid var(--medium-color);
  border-radius: 3px;
  outline: none;
  margin: .5rem auto 1rem;
  padding: .5rem;
  transition: all .2s;
  display: block;
}

input {
  height: 2.5rem;
  font-size: 16px;
}

input:focus, textarea:focus {
  border: 1px solid var(--pop-color);
}

input:-moz-ui-invalid, input:invalid {
  box-shadow: none;
  outline: none;
}

input[data-invalid="true"] {
  border: 1px solid red;
}

textarea {
  height: 7rem;
}

.estimate button {
  border: 2px solid var(--pop-color);
  margin: 3rem auto;
}

input:disabled, textarea:disabled, button:disabled {
  color: #666;
  cursor: wait;
  background-color: #eee;
  border: 1px solid #666;
}

.footer__logo {
  width: 80%;
  max-width: 600px;
  align-self: center;
  margin: 150px auto 3rem;
  display: block;
}

.footer__logo img {
  width: 100%;
}

.footer__info {
  width: 75%;
  max-width: 500px;
  grid-gap: 1rem;
  grid-template: repeat(6, 1fr) / 4fr 1fr 15fr;
  align-items: center;
  margin: 3rem auto;
  display: grid;
}

.footer_license {
  grid-column: 3 / 4;
}

.footer__inline-icon {
  height: var(--body-size);
  grid-column: 2 / 3;
  justify-self: center;
}

.disa {
  grid-column: 1 / 4;
  justify-self: center;
  margin-top: 25px;
}

.disa img {
  height: 62.7px;
  width: 75px;
  display: block;
}

#photo-page__header {
  top: 0;
}

.photo-page {
  margin-top: 4rem;
}

.photo-page__heading {
  text-align: center;
  font-size: 48px;
}

.photo-page__sub-heading {
  text-align: center;
  font-size: 36px;
}

.photo-page__container {
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
}

.photo-page__container img {
  max-width: 300px;
  width: 80%;
  height: 80%;
  box-shadow: var(--shadow);
  border-radius: 5px;
  margin: 2rem;
}

.featured-image {
  max-width: 700px !important;
}

@media (min-width: 900px) {
  :root {
    --h1-size: 4rem;
    --h2-size: 3rem;
    --h3-size: 1.75rem;
    --body-size: 1.125rem;
  }

  .heading-emphasis:before, .heading-emphasis:after {
    max-width: 400px;
  }

  .hamburger {
    display: none;
  }

  header {
    height: 50px;
    right: 0;
  }

  nav {
    width: 85%;
    grid-template: 1fr / repeat(6, 1fr);
    margin: 0 auto;
  }

  nav a {
    font-size: var(--body-size);
  }

  footer {
    grid-template: "logo contact";
    display: grid;
  }

  .footer__logo {
    grid-area: logo;
    margin-top: 0;
  }

  .footer__info {
    border-left: 2px solid var(--medium-color);
    grid-area: contact;
  }

  .photo-page {
    margin-top: 4rem;
  }

  .home__container {
    min-height: 600px;
  }

  .home__container img {
    margin: 8rem auto 1rem;
  }

  .home__container blockquote {
    font-size: 2.25rem;
  }
}

body {
  opacity: 1;
}

.hide, .see-last-class {
  display: none;
}

/*# sourceMappingURL=index.8f7bcf91.css.map */
